import React from 'react';
import {Link} from "gatsby";
import styles from "./goToCartContinueShopping.module.css";

const GoToCartContinueShopping = () => {
    return (
        <div className={styles.container}>
            <Link to={'shop'}>Continue Shopping</Link>
            <Link to={'cart'}>Go to Cart</Link>
        </div>
    )
}

export default GoToCartContinueShopping;



