import React, {useContext, useState} from 'react';
import SelectedProductContext from '../context/selectedProductsContext';
import styles from "./quantityAndAddToCart.module.css";
import {getFormattedPrice} from "../utils/numberFormatting";
import GoToCartContinueShopping from './goToCartContinueShopping';

const QuantityAndAddToCart = ({id, price, image, name, weight}) => {
    const selectedProducts = useContext(SelectedProductContext);

    const [quantity, setQuantity] = useState(1);

    const [clicked, setClicked] = useState(false);

    const reduceQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    }

    const addToCart = () => {
        const clonedSelectedProductArray = [...selectedProducts.selectedProducts];

        const containsId = clonedSelectedProductArray.map(product => product.id).indexOf(id);

        if (containsId >= 0) {
            clonedSelectedProductArray[containsId].quantity = Number(clonedSelectedProductArray[containsId].quantity) + quantity;
        } else {
            clonedSelectedProductArray.push({
                id,
                price,
                image,
                name,
                weight,
                quantity: quantity.toString()
            })
        }

        selectedProducts.setSelectedProducts(clonedSelectedProductArray);

        setClicked(true);
    }

    return (
        <div className={styles.container}>
            <label
                className={styles.label}
                htmlFor='quantity'
            >QUANTITY
            </label>
            <div
                className={styles.containerUpdateQuantity}
            >
                <button
                    className={styles.minusButton}
                    type="button"
                    data-id=""
                    onClick={(e) => reduceQuantity()}
                >−
                </button>
                <input
                    className={styles.quantityInput}
                    type="integer"
                    value={quantity}
                    min="1" data-id=""
                    name="quantity"
                    id="quantity"
                    data-submit=""
                />
                <button
                    className={styles.addButton}
                    type="button"
                    data-id=""
                    onClick={(e) => increaseQuantity()}>+
                </button>
            </div>
            <button
                className={styles.addToCartButton}
                onClick={(e) => addToCart()}
            >{`Add to Cart • ${getFormattedPrice(price * quantity)}`}
            </button>
            {clicked && <GoToCartContinueShopping/> }
        </div>
    )
}

export default QuantityAndAddToCart;



