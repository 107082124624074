import React from 'react';
import {graphql} from 'gatsby';
import Layout, {designSystem} from '../components/layout';
import SEO from "../components/seo";
import headerStyles from "../components/header.module.css";
import Header from "../components/header";
import QuantityAndAddToCart from '../components/quantityAndAddToCart'
import styles from "./productPage.module.css";
import { getFormattedPrice } from "../utils/numberFormatting";

const productPage = ({data}) => {

const { id, image, price, attributes, product } = data.stripeSku;

    return (
        <Layout>
            <SEO title="Product Page"/>
            <div
                className={styles.pageContainer}
            >
                <div
                    className={headerStyles.header}
                >
                    <Header
                        headerTextColour={designSystem.palette.black}
                        backgroundColour={designSystem.palette.white}
                        backgroundColourSmallScreen={designSystem.palette.white}
                        showSmallHeading={true}
                    />
                </div>
                <div
                    className={styles.content}
                >
                    <div
                        className={styles.productSection}
                    >
                        <div
                            className={styles.productImage}>
                            <img alt={`Photograph of ${attributes.name} product`} src={image}/>
                        </div>
                        <div
                            className={styles.productDetails}>
                            <section>
                                {`${getFormattedPrice(price)}`}
                            </section>
                            <h2>
                                {attributes.name}
                            </h2>
                            <section>
                                {product.metadata.description}
                            </section>
                            <QuantityAndAddToCart
                                id={id}
                                price={price}
                                image={image}
                                name={attributes.name}
                                weight={product.metadata.weight}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default productPage;

export const query = graphql`
    query DetailedProductQuery($id: String) {
  stripeSku(id: {eq: $id }) {
    attributes {
      name
    }
    product {
        metadata {
            description
        }
    }
    id
    image
    currency
    price
  }
}
`;